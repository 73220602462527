import { ListenerCallback, PluginListenerHandle } from '@capacitor/core';

export enum AliveCorDevice {
  'KARDIA_MOBILE' = 'KARDIA_MOBILE', // Represents the Kardia Mobile EKG Device
  'KARDIA_BAND' = 'KARDIA_BAND', // Represents the Kardia Band ECG watchband. The Android SDK does not support this device
  'TRIANGLE' = 'TRIANGLE', // Represents the KardiaMobile 6L bluetooth six-lead device
  'OMRON_COMPLETE' = 'OMRON_COMPLETE', // Represents the Omron Complete Blood Pressure and EKG device
  'UNSPECIFIED' = 'UNSPECIFIED',
}

export interface AliveCorDeviceInfo {
  device: AliveCorDevice;
  hardwareVersion: string;
  firmwareVersion: string;
}

export interface AliveCorPluginResponse {
  file: string;
  heartRate: number;
  determination: string;
  deviceInformation: AliveCorDeviceInfo;
  softwareVersion: string;
}

export interface AliveCorPlugin {
  startRecording(options: {
    partnerId: string;
    patientMrn: string;
    teamId: string;
    baseUrl: string;
    environment: 'PRODUCTION' | 'STAGING';
    applicationName: string;
    patient: { firstname: string; surname: string; birthdate: string };
  }): Promise<AliveCorPluginResponse | null>;

  dismiss(): Promise<void>;

  addListener(
    eventName: string,
    listenerFunc: ListenerCallback,
  ): PluginListenerHandle;
}
