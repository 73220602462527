import { Component } from '@angular/core';

@Component({
  selector: 'pro-message-no-message-image',
  template: `<ion-img
      src="assets/images/messages-no-messages.svg"
      (ionImgDidLoad)="ionImgDidLoad()"
    ></ion-img>
    <div *ngIf="isImageLoaded" i18n>Aktuell keine Nachrichten.</div>`,
  styles: [
    `
      :host {
        position: relative;
      }

      div {
        position: absolute;
        bottom: 17px;
        width: 100%;
        text-align: center;
        font-size: 1rem;
        font-weight: 700;
        color: var(--ion-color-primary);
      }
    `,
  ],
})
export class MessageNoMessageImageComponent {
  isImageLoaded = false;

  ionImgDidLoad() {
    this.isImageLoaded = true;
  }
}
